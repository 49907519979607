
@media print {
    .wrap {
        width: 210mm;
        height: 297mm;
        margin: 0;
        padding: 10mm;
        box-sizing: border-box;
    }

    .container {
        width: 100%;
        height: auto;
    }

    .resultInner-top,
    .resultInner-middle,
    .resultInner-bottom {
        page-break-inside: avoid;
    }

    .result-chart,
    .result-table {
        width: 50%;
        height: auto;
    }

    .no-print {
        display: none;
    }

    .btn-type004 {
        display: none;
    }

    body {
        background-color: #fff !important;
    }

    .wrap,
    .container,
    .content-w-result {
        background-color: #fff !important;
    }

    .MuiPaper-root {
        background-color: transparent !important;
    }
   
    .chart-container {
        width: 50%;
        height: auto;
    }
    .chartLabel-w .result-title {
        font-size: 10px; /* 폰트 크기 설정 */
    }
}