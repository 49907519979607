@charset "UTF-8";

/*reset*/
html, body {width:100%; -webkit-text-size-adjust:none;}
body.scrollNone{overflow:hidden;position:fixed;width:100%;}
html {overflow:auto;}
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td,button {margin:0; padding:0;font-family:'Pretendard', '맑은 고딕', 'Malgun Gothic', 'Apple SD Gothic Neo', 'Dotum', '돋움', 'Gulim', '굴림', '굴림체', Sans-Serif;}
table {border-collapse:collapse; border-spacing:0; empty-cells:show; table-layout:fixed;}
li {list-style:none;}
fieldset {background:none; border:none;}
legend, caption {position:absolute; top:-99999px; left:-99999px;}
label {cursor:text;}
input:checked[type="checkbox"] {-webkit-appearance:checkbox; background-color:#fff;}
input[type="button"], input[type="text"], input[type="image"] {-webkit-appearance:none;-webkit-border-radius:0;}
input, textarea, select {font-family:inherit; font-size:inherit; font-weight:inherit;}
textarea, input {outline-color:-moz-use-text-color; /*outline-style:none;*/ outline-width:medium;}
/*input:-webkit-autofill {-webkit-box-shadow: 0 0 0 1000px white inset; background:none !important;}*/
textarea {resize:none;-webkit-appearance:none; -webkit-border-radius:0;}
address, caption, cite, code, dfn, em, strong, th, var {font-style:normal; font-weight:normal;}
abbr, acronym {border:0; font-variant:normal;}
strong {font-weight:bold;}
img {border:0;}
em, i {font-style:normal;}
a {text-decoration:none; cursor:pointer;}
a:hover, .a:focus {text-decoration:underline;   }
button {margin:0; padding:0; border:none; cursor:pointer; background:none;}
button:disabled{cursor:initial;}
button::-moz-focus-inner {padding:0; border:0;}
.hide {position:absolute; top:-9999em; left:-9999em; font-size:1px; opacity:0; text-indent:-9999em;}
ul,ol,li {list-style: none;}

/* Skip Navigation */
#skip_nav {position:absolute; top:0; left:0; width:100%;}
#skip_nav a {position:absolute; top:-99999px; left:-99999px; display:block; width:100%; height:60px; font-weight:bold; font-size:17px; text-align:center; color:#fff; line-height:60px; border-bottom:1px solid #fcfcfc; background-color:#015293}
#skip_nav a:focus {z-index:100000; top:0; left:0;}



/* font */
@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  src:
          url('../font/Pretendard-Regular.woff2') format('woff2'),
          url('../font/Pretendard-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  src:
          url('../font/Pretendard-Medium.woff2') format('woff2'),
          url('../font/Pretendard-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  src:
          url('../font/Pretendard-Bold.woff2') format('woff2'),
          url('../font/Pretendard-Bold.woff') format('woff');
}