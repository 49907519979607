@charset "UTF-8";


/* =============== ####학교장애인식지수 온라인검사시스템 =============== */

/* -------------------- 반응형 모듈 ----------------------- */

:root{
  /* font color : 000~099 무채색 */
  --font-color000:transparent;
  --font-color001:#1A1A1A;
  --font-color002:#FFF;
  --font-color003:#565656;
  --font-color004:#D6D6D6;
  --font-color005:#6A6A6A;
  --font-color006:#404040;
  --font-color007:#8F8F8F;

  /* font color : 100~    유채색 */
  --font-color100:#146AFF;
  --font-color101:#2893FF;
  --font-color102:#2977FF;
  --font-color103:#FFD600;
  --font-color104:#477EFF;
  --font-color105:#FF96CA;
  --font-color106:#64B7FF;
  --font-color107:#18CCC0;

  /* background color : 000~099 무채색 */
  --ui-color000:transparent;
  --ui-color001:#000;
  --ui-color002:#FFF;
  --ui-color003:#DDD;
  --ui-color004:#EEE;
  --ui-color005:#404040;
  --ui-color006:#6A6A6A;
  --ui-color007:#D6D6D6;
  --ui-color008:#828282;
  --ui-color009:#C5C5C5;
  --ui-color010:#3D3D3D;
  --ui-color011:#F6F6F6;
  --ui-color012:#F2F2F2;
  --ui-color013:#E2E2E2;
  --ui-color014:#FAFAFA;
  --ui-color015:#F1F1F1;

  /* background color : 100~    유채색 */
  --ui-color100:#32B9FF;
  --ui-color101:#0048F2;
  --ui-color102:#4786FF;
  --ui-color103:#0A48B4;
  --ui-color104:#2977FF;
  --ui-color105:#477EFF;
  --ui-color106:#FFD600;
  --ui-color107:#3F78FF;
  --ui-color108:#FF9721;
  --ui-color109:#ECF8FA;
  --ui-color110:#EBF2FF;
  --ui-color111:#4EADFF;
  --ui-color112:#FFCB00;
  --ui-color113:#FFF6C0;
  --ui-color114:#146AFF;
  --ui-color115:#FF9721;

  /* filter : 무채색 */
  --filter-color000:transparent; /* (default) */
  --filter-color001:brightness(0) invert(1); /* 모든색 → #fff */
  --filter-color002:brightness(0) invert(0); /* 모든색 → #000 */

  /* filter : 유채색 */
  --filter-color100:brightness(0) saturate(100%) invert(40%) sepia(23%) saturate(4633%) hue-rotate(319deg) brightness(100%) contrast(103%); /* #FE4275 */
}


/* ---- ## logo-$$$ : 로고 ---- */
[class*='logo-']{display:block;background-size:100%;background-position:center;background-repeat:no-repeat;transition:all .3s;}
.logo-system{width:228px;height:89px;background-image:url("../image/logo_system.png");}
.logo-univ{width:145px;height:145px;background-image:url("../image/logo_gyeonginUniv.png");}
.logo-result{width:585px;height:50px;background-image:url("../image/logo_resultpage.png");}
@media all and (max-width:1024px){
  .logo-system{width:10rem;height:4rem;}
  .logo-univ{width:6.5rem;height:6.5rem;}
  .logo-result{width:470px;height:40px;}
}
@media all and (max-width:768px){
  .logo-system{width:110px;height:48px;}
  .logo-result{width:258px;height:22px;}
}

/* ---- ## ico-type$$$ : 아이콘 ---- */
[class*='ico-type']{display:inline-flex;background-size:100%;background-position:center;background-repeat:no-repeat;}
.ico-type001{width:22px;height:24px;}
  .ico-type001.video{width:22px;height:14px;background-size:22px auto;background-image:url("../image/icon_video.svg");}
  .ico-type001.home{background-image:url("../image/icon_home.svg");}
  .ico-type001.search{background-size:22px auto;background-image:url("../image/icon_search.svg");}
  .ico-type001.mypage{background-image:url("../image/icon_my.svg");}
  .ico-type001.logout{background-image:url("../image/icon_logout.svg");}
  .ico-type001.menu{background-image:url("../image/icon_menu.svg");}
  .ico-type001.local{height:26px !important;background-image:url("../image/icon_local.svg");}
.ico-type002{width:32px;height:27px;}
  .ico-type002.password{background-size:32px auto;}
  .ico-type002.password.on{background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31.5' height='21.234' viewBox='0 0 31.5 21.234'%3E%3Cscript xmlns=''/%3E%3Cpath id='Icon_ionic-md-eye' data-name='Icon ionic-md-eye' d='M18,7.383A16.936,16.936,0,0,0,2.25,18a16.991,16.991,0,0,0,31.5,0A16.936,16.936,0,0,0,18,7.383Zm0,17.7A7.081,7.081,0,1,1,25.158,18,7.139,7.139,0,0,1,18,25.08Zm0-11.327A4.247,4.247,0,1,0,22.3,18,4.285,4.285,0,0,0,18,13.753Z' transform='translate(-2.25 -7.383)' fill='%23b4b4b4'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
  .ico-type002.password.off{background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31.507' height='27' viewBox='0 0 31.507 27'%3E%3Cscript xmlns=''/%3E%3Cpath id='Icon_ionic-md-eye-off' data-name='Icon ionic-md-eye-off' d='M18.007,10.181a7.142,7.142,0,0,1,7.165,7.109,6.838,6.838,0,0,1-.513,2.6l4.184,4.148a16.809,16.809,0,0,0,4.915-6.75A16.968,16.968,0,0,0,12.291,7.622l3.094,3.073A7.084,7.084,0,0,1,18.007,10.181ZM3.684,6.307l3.27,3.241.661.654A16.729,16.729,0,0,0,2.25,17.29a16.993,16.993,0,0,0,22.029,9.464l.6.6,4.2,4.148L30.9,29.693,5.5,4.5ZM11.6,14.161l2.222,2.2a4.028,4.028,0,0,0-.113.921,4.275,4.275,0,0,0,4.3,4.261,4.041,4.041,0,0,0,.928-.112l2.222,2.2a7.127,7.127,0,0,1-10.315-6.356A7.023,7.023,0,0,1,11.6,14.161Zm6.173-1.1,4.514,4.479.028-.225a4.275,4.275,0,0,0-4.3-4.261Z' transform='translate(-2.25 -4.5)' fill='%23b4b4b4'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
  .ico-type002.copy{background-size:32px 27px;background-image:url("../image/icon_copy.svg");}
  .ico-type002.print{background-size:32px 27px;background-image:url("../image/icon_print.svg");}
.ico-type003{width:11px;height:10px;background-size:11px auto;}
  .ico-type003.sort.desc{background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10'%3E%3Cscript xmlns=''/%3E%3Cg id='다각형_12' data-name='다각형 12' transform='translate(11 10) rotate(180)' fill='%23fff'%3E%3Cpath d='M 10.15436172485352 9.5 L 0.8456379771232605 9.5 L 5.5 1.037523627281189 L 10.15436172485352 9.5 Z' stroke='none'/%3E%3Cpath d='M 5.5 2.075047492980957 L 1.691275596618652 9 L 9.308724403381348 9 L 5.5 2.075047492980957 M 5.5 0 L 11 10 L 0 10 L 5.5 0 Z' stroke='none' fill='%23a1a1a1'/%3E%3C/g%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
  .ico-type003.sort.asc{background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10'%3E%3Cscript xmlns=''/%3E%3Cg id='다각형_11' data-name='다각형 11' fill='%23fff'%3E%3Cpath d='M 10.15436172485352 9.5 L 0.8456379771232605 9.5 L 5.5 1.037523627281189 L 10.15436172485352 9.5 Z' stroke='none'/%3E%3Cpath d='M 5.5 2.075047492980957 L 1.691275596618652 9 L 9.308724403381348 9 L 5.5 2.075047492980957 M 5.5 0 L 11 10 L 0 10 L 5.5 0 Z' stroke='none' fill='%23a1a1a1'/%3E%3C/g%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
.ico-type004{width:40px;height:40px;background-size:100%;}
  .ico-type004.close{background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39.641' height='39.641' viewBox='0 0 39.641 39.641'%3E%3Cscript xmlns=''/%3E%3Cpath id='Icon_metro-cancel' data-name='Icon metro-cancel' d='M22.391,1.928A19.821,19.821,0,1,0,42.212,21.749,19.821,19.821,0,0,0,22.391,1.928Zm0,35.925a16.1,16.1,0,1,1,16.1-16.1A16.1,16.1,0,0,1,22.391,37.853Zm6.194-26.015-6.194,6.194L16.2,11.838l-3.716,3.716,6.194,6.194-6.194,6.194L16.2,31.659l6.194-6.194,6.194,6.194L32.3,27.943l-6.194-6.194L32.3,15.555Z' transform='translate(-2.571 -1.928)' fill='%236a6a6a'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
  .ico-type004.view{width:24px;height:24px;background-image:url("../image/icon_view.svg");}
.ico-type005{position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);width:18px;height:18px;}
  .ico-type005.plus{background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.711' height='18.672' viewBox='0 0 18.711 18.672'%3E%3Cpath id='패스_749' data-name='패스 749' d='M10.82-1.8h3.75V-9.258h7.5v-3.75h-7.5v-7.461H10.82v7.461H3.359v3.75H10.82Z' transform='translate(-3.359 20.469)' fill='%236a6a6a'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
  .ico-type005.minus{width:14px;height:5px;background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.539' height='3.594' viewBox='0 0 12.539 3.594'%3E%3Cpath id='패스_750' data-name='패스 750' d='M15.039-13.75H2.5v3.594H15.039Z' transform='translate(-2.5 13.75)' fill='%236a6a6a'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
@media all and (max-width:768px){
  .ico-type004{width:20px;height:20px;}
}


/* 레이블 */
[class*='ico-label']{padding:12px 36px;display:inline-flex;align-items:center;justify-content:center;}
[class*='ico-label'] [class*="txt-"]{font-size:46px;font-weight:700;}
[class*='ico-label'] .txt-pink{color:var(--font-color105);}
[class*='ico-label'] .txt-blue{color:var(--font-color106);}
[class*='ico-label'] .txt-yellow{color:var(--font-color103);}
[class*='ico-label'] .txt-mint{color:var(--font-color107);}
.ico-label-blue{background-color:var(--ui-color114);border-radius:40px;}
.ico-label-white{background-color:var(--ui-color002);border-radius:40px;}
@media all and (max-width:1280px){
  [class*='ico-label']{padding:12px 28px;}
  [class*='ico-label'] [class*="txt-"]{font-size:30px;}
}
@media all and (max-width:1024px){
  [class*='ico-label']{padding:8px 20px;}
  [class*='ico-label'] [class*="txt-"]{font-size:24px;}
}

/* ---- ## btn-type$$$ : 버튼 공통 ---- */
[class*='btn-type']{display:flex;flex-shrink:0;align-items:center;justify-content:center;box-sizing:border-box;}
.btn-type001{padding:8px;font-size:18px;font-weight:700;color:var(--font-color003);}
  .btn-type001.local{padding:8px 17px;}
    /*.btn-type001.local .ico-type001{display:none;}*/
  .btn-type001 .ico-type001{margin-right:10px;}
.btn-type002{width:84px;height:84px;border-radius:100%;border:1px solid var(--ui-color003);background-color:var(--ui-color002);}
  .btn-type002 .ico-arrow{display:block;width:50%;height:50%;background-size:100%;background-repeat:no-repeat;background-position:center;background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='38.704' height='36.633' viewBox='0 0 38.704 36.633'%3E%3Cscript xmlns=''/%3E%3Cg id='Icon_feather-arrow-down' data-name='Icon feather-arrow-down' transform='translate(3.536 2.5)'%3E%3Cpath id='패스_359' data-name='패스 359' d='M18,39.133V7.5' transform='translate(-2.183 -7.5)' fill='none' stroke='%232977ff' stroke-linecap='round' stroke-linejoin='round' stroke-width='5'/%3E%3Cpath id='패스_360' data-name='패스 360' d='M39.133,33.817,23.317,18,7.5,33.817' transform='translate(-7.5 -18)' fill='none' stroke='%232977ff' stroke-linecap='round' stroke-linejoin='round' stroke-width='5'/%3E%3C/g%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
  .btn-type002:hover {background-color:var(--ui-color104);}
  .btn-type002:hover .ico-arrow{filter:var(--filter-color001);}
.btn-type003{display:flex;flex-direction:column;align-items:center;}
  .btn-type003 [class*="ico-"]{width:242px;height:242px;background-position:center;background-repeat:no-repeat;background-size:50%;border-radius:100%;border:10px solid var(--ui-color004);}
    .btn-type003:hover [class*="ico-"]{border-color:var(--ui-color106);}
  .btn-type003 .ico-student{background-image:url("../image/icon_pencil.svg");background-color:var(--ui-color100);}
  .btn-type003 .ico-teacher{background-image:url("../image/icon_book.svg");background-color:var(--ui-color101);}
  .btn-type003 .ico-office{background-image:url("../image/icon_school.svg");background-color:var(--ui-color102);}
  .btn-type003 .txt{padding-top:17px;font-size:28px;font-weight:700;color:var(--font-color100);}
.btn-type004{display:inline-flex;align-items:center;justify-content:center;}
  .btn-type004.login-type01{padding:17px 34px;font-size:18px;color:var(--font-color002);font-weight:700;border-radius:36px;background-color:var(--ui-color001);}
  .btn-type004.login-type01.on .mypage{width:18px;height:18px;margin-right:5px;background-size:100%;filter:var(--filter-color001);}
  .btn-type004.login-type01.off{background-color:var(--ui-color009);}
  .btn-type004.login-type01.off .logout{display:none;}
  .btn-type004.login-type02{padding:25px 235px;font-size:28px;color:var(--font-color002);font-weight:500;border-radius:8px;background-color:var(--ui-color105);white-space:nowrap;}
  .btn-type004.tab{padding:20px 60px;font-size:18px;color:var(--font-color001);font-weight:700;border-radius:50px;border:1px solid var(--ui-color001);}
    .btn-type004.tab:hover,
    .btn-type004.tab.current{color:var(--font-color002);background-color:var(--ui-color005);border-color:var(--ui-color006);}
.btn-type005{display:inline-flex;padding:20px 60px;font-size:18px;color:var(--font-color002);font-weight:500;border-radius:8px;}
  .btn-type005.blue{background-color:var(--ui-color105);}
    .btn-type005.blue:hover{background-color:var(--ui-color103);}
  .btn-type005.grey{background-color:var(--ui-color008);}
  .btn-type005.border{color:var(--font-color001);background-color:var(--ui-color002);border:1px solid var(--ui-color008);}
    .btn-type005.border:hover{background-color:var(--ui-color006);color:var(--font-color002);text-decoration:none;}
  .btn-type005.yellow{background-color:var(--ui-color106);color:var(--font-color001);font-weight:700;border-radius:30px;}
    .btn-type005.yellow:hover{background-color:var(--ui-color112);text-decoration:none;}
  .btn-type005.orange{color:var(--font-color001);background-color:var(--ui-color002);border:1px solid var(--ui-color008);}
  .btn-type005.orange:hover,
  .btn-type005.orange.current{color:var(--font-color002);border-color:var(--ui-color115);background-color:var(--ui-color115);}
.btn-type006{position:relative;width:50px;height:50px;border:1px solid var(--ui-color003);border-radius:50%;text-indent:-99999em;}
  .btn-type006:hover{background-color:var(--ui-color105);}
  .btn-type006:hover .ico-type005{filter:var(--filter-color001);}
/* btns-group$$ : 버튼 그룹 */
[class*="btns-group"]{position:relative;display:inline-flex;align-items:center;}
.btns-group01{margin-left:auto;gap:15px;}
.btns-group01 .btn-type001.home{margin-right:20px;}
.btns-group02{gap:5vw;}
.btns-group03{justify-content:center;gap:10px;margin-top:30px;}
.btns-group04{flex-direction:column;}
  .btns-group04 .list-wrap02{display:none;position:absolute;z-index:7;top:40px;left:50%;transform:translateX(-50%);}
  .btns-group04 .list-wrap02.active{display:block;}
.btns-group05{padding-top:30px;justify-content:center;gap:10px;} /* 버튼 4개인 경우 */
.btns-group05 .btn-type005{width:25%;padding:20px 30px;white-space:nowrap;}

@media all and (max-width:1024px){
  .btn-type001 .txt01{font-size:18px;}
  .btn-type002{width:60px;height:60px;}
  .btn-type003 [class*="ico-"]{width:11rem;height:11rem;}
  .btn-type003 .txt{font-size:23px;}
  .btn-type004.login-type01{padding:12px 24px;font-size:15px;}
  .btn-type004.login-type01 .ico-type001{width:15px;height:15px;}
  .btn-type004.tab{padding:18px 24px;}
  .btn-type005{padding:16px 50px;font-size:16px;}
  /* 버튼 그룹 */
  .btns-group01{gap:10px;}
}
@media all and (max-width:768px){
  .btn-type001 .txt01,
  .btn-type004.login-type01.on .txt01,
  .btn-type004.login-type01.off .txt01{display:none;}
  .btns-group01 .btn-type001.home,
  .btn-type001.home .ico-type001{margin-right:0;}
  .btn-type001.local .ico-type001{display:block;}
  .btn-type001.local{padding:8px;}
  .btn-type004.login-type01.on,
  .btn-type004.login-type01.off{padding:8px;background-color:var(--ui-color000) !important;}
  .btn-type001 .ico-type001,
  .btn-type004.login-type01 .ico-type001{width:20px;height:21px;}
  .btn-type003 [class*="ico-"]{width:7rem;height:7rem;}
  .btn-type004.login-type01.on .ico-type001.mypage{margin-right:0;filter:var(--filter-color002);}
  .btn-type004.login-type01.off .logout{display:block;}
  .btn-type005{padding:15px 30px;font-size:14px;}
  /* 버튼 그룹 */
  .btns-group02{flex-wrap:wrap;justify-content:center;}
}
@media all and (max-width:320px){
  .btns-group01{gap:5px;}
  .btn-type001 .ico-type001,
  .btn-type004.login-type01 .ico-type001{width:18px;height:19px;}
  .btn-type003 [class*="ico-"]{width:5rem;height:5rem;}
}

/* ---- ## pading-type$$ : 페이징 공통 ---- */
.paging-type01{padding:30px 0;display:flex;justify-content:center;align-items:center;width:100%;gap:8px;}
.paging-type01 .first{display:block;width:30px;height:30px;background-color:var(--font-color000);background-image:url("../image/icon_first.svg");background-size:13px;background-position:center center;background-repeat:no-repeat;}
.paging-type01 .prev{display:block;width:30px;height:30px;background-color:var(--font-color000);background-image:url("../image/icon_prev.svg");background-size:7px 13px;background-position:center center;background-repeat:no-repeat;}
.paging-type01 .next{display:block;width:30px;height:30px;background-color:var(--font-color000);background-image:url("../image/icon_next.svg");background-size:7px 13px;background-position:center center;background-repeat:no-repeat;}
.paging-type01 .last{display:block;width:30px;height:30px;background-color:var(--font-color000);background-image:url("../image/icon_last.svg");background-size:13px;background-position:center center;background-repeat:no-repeat;}
.paging-type01 .num{display:flex;justify-content:center;align-items:center;min-width:30px;height:30px;font-size:18px;font-weight:400;color:var(--font-color005);}
.paging-type01 .num.on,
.paging-type01 .num:hover{color:var(--font-color001);font-weight:700;}
.paging-type01 .first:hover,
.paging-type01 .prev:hover,
.paging-type01 .next:hover,
.paging-type01 .last:hover{filter:var(--filter-color002);}


/* ---- ## input-radio$$ : 레이블 공통 ---- */
[class*='input-radio']{display:inline-block;position:relative;font-size:0;}
input.radio01,
input.check01,
input.toggle01{opacity:0;position:absolute;top:0;left:0;z-index:1;width:100%;height:100%;border:none;font-size:0;line-height:0;}
[class*='input-radio'] ~ .txt-mark01{display:inline-block;width:15px;height:48px;font-size:16px;line-height:48px;text-align:center;vertical-align:middle;}
[class*='input-radio'] .txt-label01{position:relative;padding-left:45px;font-size:28px;line-height:1.2;font-weight:500;color:var(--font-color001);white-space:nowrap;}
[class*='input-radio'] .txt-label01.hide{width:18px;padding-left:0;text-indent:-9999px;}
[class*='input-radio'] .txt-label01.blue{color:var(--font-color100);}
[class*='input-radio'] .txt-label01:before,
[class*='input-radio'] .txt-label01:after{content:'';position:absolute;box-sizing:border-box;border-radius:50%;}
[class*='input-radio'] .txt-label01:before{top:2px;left:0;width:32px;height:32px;border:1px solid var(--ui-color007);}
[class*='input-radio'] .txt-label01:after{top:8px;left:6px;width:20px;height:20px;background-color:var(--ui-color007);}
/* 활성화 */
[class*='input-radio'].active .txt-label01:before,
[class*='input-radio'] input:checked~.txt-label01:before{border-color:var(--ui-color007);}
[class*='input-radio'].active .txt-label01:after,
[class*='input-radio'] input:checked~.txt-label01:after{background-color:var(--font-color101);}
/* form */
.radio-form{display:flex;flex-direction:row;gap:30px;}
@media all and (max-width:1024px){
  [class*='input-radio'] .txt-label01{font-size:23px;}
  [class*='input-radio'] .txt-label01:before{width:27px;height:27px;}
  [class*='input-radio'] .txt-label01:after,
  [class*='input-radio'] input:checked~.txt-label01:after{top:7px;left:5px;width:17px;height:17px;}
  .radio-form{gap:20px;}
}
@media all and (max-width:768px){
  [class*='input-radio'] .txt-label01{padding-left:35px;font-size:16px;}
  [class*='input-radio'] .txt-label01:before{top:0;width:23px;height:23px;}
  [class*='input-radio'] .txt-label01:after,
  [class*='input-radio'] input:checked~.txt-label01:after{top:4px;left:4px;width:15px;height:15px;}
  .radio-form{gap:15px;}
}



/* ---- ## input type="$$" : 인풋 공통 ---- */
[class*='input-type']{position:relative;display:flex;align-items:center;width:100%;box-sizing:border-box;}
[class*='input-type']>input{box-sizing:border-box;padding:20px 24px;border:none;font-size:20px;font-weight:500;color:var(--font-color001);width:100%;height:100%;background-color:var(--ui-color000);}
[class*='input-type']>input::placeholder{font-size:20px;font-weight:500;color:var(--font-color004);}
[class*='input-type']>input:disabled{color:var(--font-color004);}
[class*='input-type']::selection{font-size:16px;}
/* ---- ## input type="text" ---- */
.input-type01{border:1px solid var(--ui-color007);background-color:var(--ui-color002);border-radius:8px;}
.input-type01>input:focus{outline-color:var(--ui-color101);}
/* ---- ## input type="check" ---- */
.input-check01{position:relative;padding-left:40px;}
.input-check01>input{position:absolute;top:0;left:0;z-index:2;height:30px;width:30px;opacity:0;}
.input-check01 .txt01{font-size:28px;color:var(--font-color100);font-weight:500;}
.input-check01 .txt01:before{content:'';display:block;width:18px;height:18px;border-radius:18px;position:absolute;top:3px;left:3px;background-color:var(--ui-color000);}
.input-check01 .input:checked+.txt01:before{background-color:var(--ui-color001);}
.input-check01 .input:checked+.ico-type002{background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.25' height='29.25' viewBox='0 0 29.25 29.25'%3E%3Cscript xmlns=''/%3E%3Cpath id='Icon_ionic-ios-checkmark-circle' data-name='Icon ionic-ios-checkmark-circle' d='M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Zm7.488,10.582-9.4,9.443H16.08a1.27,1.27,0,0,1-.816.387,1.231,1.231,0,0,1-.823-.4L10.5,19.448a.28.28,0,0,1,0-.4L11.756,17.8a.272.272,0,0,1,.394,0l3.122,3.122,8.578-8.641a.278.278,0,0,1,.2-.084h0a.255.255,0,0,1,.2.084l1.23,1.273A.277.277,0,0,1,25.488,13.957Z' transform='translate(-3.375 -3.375)' fill='%23477eff'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
.input-check01 .ico-type002{position:absolute;top:0;left:0;width:30px;height:30px;background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.25' height='29.25' viewBox='0 0 29.25 29.25'%3E%3Cscript xmlns=''/%3E%3Cg id='Icon_ionic-ios-checkmark-circle-outline' data-name='Icon ionic-ios-checkmark-circle-outline' transform='translate(-3.375 -3.375)'%3E%3Cpath id='패스_3' data-name='패스 3' d='M25.5,13.563l-1.237-1.273a.266.266,0,0,0-.2-.084h0a.255.255,0,0,0-.2.084l-8.578,8.641L12.164,17.81a.272.272,0,0,0-.394,0l-1.252,1.252a.28.28,0,0,0,0,.4L14.456,23.4a1.245,1.245,0,0,0,.823.4,1.3,1.3,0,0,0,.816-.387H16.1l9.4-9.45A.3.3,0,0,0,25.5,13.563Z' fill='%236a6a6a'/%3E%3Cpath id='패스_4' data-name='패스 4' d='M18,5.344A12.651,12.651,0,1,1,9.049,9.049,12.573,12.573,0,0,1,18,5.344m0-1.969A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z' fill='%236a6a6a'/%3E%3C/g%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
/* ---- ## input type="date" ---- */
input[type="date"]:valid{color:var(--font-color001);}
.input-date001{position:relative;display:flex;align-items:center;width:100%;border-radius:6px;box-sizing:border-box;border:1px solid var(--ui-color007);}
.input-date001>input{width:100%;height:100%;padding:20px 24px;box-sizing:border-box;font-size:20px;font-weight:500;border-color:var(--ui-color000);background-color:var(--ui-color000);background-image:url("../image/icon_calendar.svg");background-size:24px 24px;background-repeat:no-repeat;background-position:right 12px center;cursor:pointer;}
.input-date001>input::-webkit-calendar-picker-indicator{opacity:0;}
.input-date001>input:focus{outline-color:var(--ui-color101);}
.input-date001>input[type=date]:valid {color:var(--font-color001);}
.searchDate-wrap{display:flex;justify-content:space-between;gap:6px;}
/* ---- ## input type="file" ---- */
.input-file01>label{display:inline-flex;padding:20px 30px;font-size:18px;font-weight:500;color:var(--font-color002);border-radius:8px;background-color:var(--ui-color105);}
.input-file01>input{padding:20px 30px;border-radius:8px;border:1px solid var(--ui-color007);}
.input-file01>input::file-selector-button{display:none;}
/* input-group$$ : 인풋 그룹 */
.input-group01{width:100%;flex-shrink:0;display:flex;justify-content:center;}
.input-group01 .input-type01{border-radius:8px 0 0 8px;}
.input-group01 .btn-type005{border-radius:0 8px 8px 0;}
.input-group02{width:100%;display:flex;align-items:center;gap:20px;}
.input-group02 .title-type002{flex-shrink:0;min-width:100px;}
.input-group03{display:flex;flex-direction:row;gap:20px;}
/* select+option */
.select-wrap{position:relative;flex-shrink:0;}
[class*="select-type"]{box-sizing:border-box;padding:20px 24px;width:100%;border:1px solid var(--ui-color007);border-radius:8px;appearance:none;-moz-appearance:none;-ms-progress-appearance:none;-webkit-appearance:none;background-color:var(--ui-color002);}
[class*="select-type"]:focus{outline-color:var(--ui-color101);}
/*[class*="select-type"]>option{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}*/
[class*="select-type"] .tit01{font-size:20px;font-weight:500;}
[class*="select-type"] .tit01 .txt01{font-size:20px;font-weight:500;color:var(--font-color100);}
.select-type01{font-size:20px;font-weight:500;background-size:12px 11px;background-repeat:no-repeat;background-position:97% 50%;background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.477' height='12.279' viewBox='0 0 21.477 12.279'%3E%3Cscript xmlns=''/%3E%3Cpath id='Icon_ionic-ios-arrow-back' data-name='Icon ionic-ios-arrow-back' d='M19.828,16.929,11.7,8.809a1.535,1.535,0,0,1,2.174-2.167l9.207,9.2a1.532,1.532,0,0,1,.045,2.116l-9.246,9.264a1.535,1.535,0,0,1-2.174-2.167Z' transform='translate(27.671 -11.251) rotate(90)' fill='%23146aff'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
.select-type01-label{position:absolute;left:0;top:0;font-size:20px;}
@media all and (max-width:1024px){
  [class*='input-type']>input::placeholder{font-size:18px;}
  [class*='input-type']>input,
  [class*="select-type"]{padding:18px 24px;font-size:18px;}
  .input-date001>input{padding:18px 24px;font-size:18px;}
}
@media all and (max-width:768px){
  [class*='input-type']>input::placeholder{font-size:16px;}
  [class*='input-type']>input,
  [class*="select-type"]{padding:12px 8px;font-size:16px;}
  .input-date001>input{padding:10px 8px;font-size:16px;}
}

/* fieldset 그룹 */
.fieldset-type01{padding-bottom:30px;display:flex;width:100%;gap:10px;} /* 셀렉+검색 */
.fieldset-type01 .select-wrap{width:30%;}
.fieldset-type01 .input-group01{width:50%;}
.fieldset-type02{display:flex;flex-direction:column;gap:15px;width:100%;} /* 타이틀 센터+등록폼 */
.fieldset-type03{display:flex;flex-direction:column;gap:20px;margin:0 auto;} /* input='file' 타입 */
@media all and (max-width:768px){
  .fieldset-type01{flex-direction:column;}
  .fieldset-type01 .select-wrap,
  .fieldset-type01 .input-group01{width:100%;}
}


/* ---- ## title-type$$$ : 타이틀 공통 ---- */
[class*="title-type"]{display:flex;align-items:center;position:relative;box-sizing:border-box;padding:8px 0;}
[class*="title-type001"] .tit01{font-size:38px;color:var(--font-color001);font-weight:700;line-height:1.5;}
.title-type001-blue{width:100%;text-align:center;}
.title-type001-blue .tit01{width:100%;color:var(--font-color002);line-height:2;background-color:var(--ui-color111);border-radius:40px;}
.title-type002 [class*="tit01"]{font-size:28px;color:var(--font-color001);font-weight:500;line-height:1.35;}
.title-type002 .tit01-white{color:var(--font-color002);}
.title-type002 .tit01-blue{color:var(--font-color104);}
.title-type002 .tit01-grey{color:var(--font-color005);}
.title-type003{display:inline-flex;}
.title-type003 [class*="tit01"]{font-size:18px;color:var(--font-color001);line-height:1.4;}
.title-type003 .tit01-blue{font-size:18px;color:var(--font-color102);}
.title-type003 .tit01-blue .ico-arrow{display:inline-block;margin-left:5px;width:7px;height:12px;background-repeat:no-repeat;background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.751' height='11.809' viewBox='0 0 6.751 11.809'%3E%3Cscript xmlns=''/%3E%3Cpath id='Icon_ionic-ios-arrow-back' data-name='Icon ionic-ios-arrow-back' d='M15.967,12.1,11.5,7.632a.844.844,0,0,1,1.2-1.192L17.756,11.5a.842.842,0,0,1,.025,1.164L12.7,17.757a.844.844,0,0,1-1.2-1.192Z' transform='translate(-11.251 -6.194)' fill='%232977ff'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
.title-type003 .tit01-white{color:var(--font-color002);}
.title-type003 .tit01-grey{color:var(--font-color005);}
.title-type004 [class*="tit01"]{font-size:16px;color:var(--font-color001);}
.title-type005 .tit01{font-size:24px;color:var(--font-color002);}
@media all and (max-width:1024px) {
  .title-type001 .tit01{font-size:30px;}
  .title-type001-blue .tit01{font-size:28px;}
  .title-type002 [class*="tit01"]{font-size:23px;}
  .title-type003 [class*="tit01"]{font-size:16px;}
  .title-type004 [class*="tit01"]{font-size:14px;}
}
@media all and (max-width:768px) {
  .title-type001 .tit01{font-size:23px;}
  .title-type001-blue .tit01{font-size:4.5vw;line-height:1.5;padding:10px 0;}
  .title-type002 .tit01{font-size:18px;}
  /*.title-type003 [class*="tit01"]{font-size:14px;}*/
  .title-type004 [class*="tit01"]{font-size:10px;}
}
/* 타이틀 그룹 */
[class*="title-wrap"]{display:inline-flex;align-items:center;}
.title-wrap01{width:100%;}
.title-wrap01 .title-type003{margin-left:auto;}


/* ---- ## text-type$$$ : 텍스트 공통 ---- */
[class*="txt-type"]{display:inline-flex;align-items:center;position:relative;box-sizing:border-box;}
.txt-type001 .txt01{font-size:18px;color:var(--font-color005);font-weight:400;}
[class*="txt-type002"]{position:relative;margin-bottom:18px;padding:18px 44px;border-radius:10px;}
[class*="txt-type002"]:before{position:absolute;content:'';display:block;left:20px;bottom:-14px;width:28px;height:16px;background-repeat:no-repeat;background-size:100% auto;}
  .txt-type002-blue{background-color:var(--ui-color105);}
  .txt-type002-blue:before{background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.625' height='15.545' viewBox='0 0 28.625 15.545'%3E%3Cscript xmlns=''/%3E%3Cpath id='빼기_3' data-name='빼기 3' d='M1020,17686.543a4.955,4.955,0,0,1-3.977-1.971L1005.689,17671h28.625l-10.333,13.574A4.971,4.971,0,0,1,1020,17686.543Z' transform='translate(-1005.689 -17670.998)' fill='%23477eff'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
  .txt-type002-orange{background-color:var(--ui-color108);}
  .txt-type002-orange:before{background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.625' height='15.545' viewBox='0 0 28.625 15.545'%3E%3Cscript xmlns=''/%3E%3Cpath id='빼기_4' data-name='빼기 4' d='M1020,17686.543a4.955,4.955,0,0,1-3.977-1.971L1005.689,17671h28.625l-10.333,13.574A4.971,4.971,0,0,1,1020,17686.543Z' transform='translate(-1005.689 -17670.998)' fill='%23ff9721'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E");}
[class*="txt-type002"] .txt01{font-size:22px;color:var(--font-color002);font-weight:700;}
@media all and (max-width:1024px) {
  [class*="txt-type002"]{padding:15px 30px;}
  [class*="txt-type002"] .txt01{font-size:18px;}
}
@media all and (max-width:768px) {
  [class*="txt-type002"]{padding:12px 24px;}
  [class*="txt-type002"] .txt01{font-size:16px;}
}

/* ---- ## list-text$$$ : 리스트 텍스트 ---- */
[class*='list-text']{display:inline-flex;align-items:center;}
.list-text001{width:100%;}
.list-text001 .tit01{display:block;margin-right:10px;font-size:18px;color:var(--font-color006);overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.list-text001 .txt01{display:block;margin-left:auto;font-size:16px;color:var(--font-color007);white-space:nowrap;}
/* 모달용 공지사항 운영영역 */
.list-text002{flex-direction:column;align-items:flex-start;padding:30px 0;width:100%;border-style:solid;border-color:var(--ui-color003);border-width:1px 0;}
.list-text002 .tit01,
.list-text002 span,
.list-text002 p{padding:8px 0;font-size:20px;color:var(--font-color006);}
.list-text002 ul li{position:relative;}
.list-text002 ul li:before{position:absolute;content:'';display:block;left:5px;top:8px;width:4px;height:4px;border-radius:100%;background-color:var(--ui-color006);}
.list-text002 ul li .tit01,
.list-text002 ul li span{padding:8px 0 8px 16px;}

/* ---- ## list-type$$$ : 리스트 공통 ---- */
[class*='list-type']{display:inline-flex;align-items:center;}
.list-type01{flex-direction:column;max-width:100%;}
.list-type01 li{position:relative;box-sizing:border-box;padding:0 0 7px 15px;display:block;width:100%;}
.list-type01 li:before{position:absolute;content:'';display:block;left:5px;top:7px;width:4px;height:4px;border-radius:100%;background-color:var(--ui-color008);}
.list-type01 li .ico-type001{margin-right:5px;}
/* 리스트 그룹 */
.list-wrap01{display:block;padding:2rem;border:1px solid var(--ui-color007);background-color:rgba(255,255,255,.45);border-radius:10px;}
.list-wrap02{display:block;background-color:var(--ui-color014);}
.list-wrap02>ul{position:relative;}
.list-wrap02>ul>li{padding:5px 12px;border:1px solid var(--ui-color007);white-space:nowrap;}
.list-wrap02>ul>li:hover{background-color:var(--ui-color003);}
.list-wrap02>ul>li>a{font-size:12px;font-weight:700;color:var(--font-color003);line-height:18px;}
/* ---- ## table-type$$$ : 테이블 공통 ---- */
[class*='table-type']{border-collapse:collapse;width:100%;max-width:1400px;}
[class*='table-type'] th{border-style:solid;border-color:var(--ui-color006);border-width:1px 0;padding:16px 8px;font-size:18px;font-weight:700;color:var(--font-color005);white-space:nowrap;background-color:var(--ui-color011);}
[class*='table-type'] th.typeWhite{background-color:var(--ui-color002);}
[class*='table-type'] tr{border-bottom:1px solid var(--ui-color006);cursor:pointer;}
[class*='table-type'] tr:hover{background-color:var(--ui-color011);}
[class*='table-type'] td{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;text-align:left;padding:16px 8px;font-size:16px;line-height:1.4;color:var(--font-color001);vertical-align:top;}
[class*='table-type'] td.typeCenter{text-align:center;}
.table-type01{} /* 말줄임 한줄처리 */
.table-type02{} /* 말줄임 처리없음 */
  .table-type02 td{text-overflow:initial;white-space:initial;color:var(--font-color005);}
.table-type03{margin:0 auto;max-width:1000px;}
.table-type03 .title-type002{justify-content:center;} /* 학급관리 테이터 */
.table-type03 th,
.table-type03 tr,
.table-type03 tr:hover{background-color:var(--ui-color000);border-color:var(--ui-color000);}

/* 테이블 그룹 */
.commonTabel-w{padding:0 50px;}
@media all and (max-width:768px){
  [class*='table-type'] th{padding:12px 4px;font-size:10px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;}
  [class*='table-type'] td{font-size:13px;}
  .commonTabel-w{padding:0 15px;}
}