@charset "UTF-8";


/* =============== ####학교장애인식지수 온라인검사시스템 =============== */

/* -------------------- 공통 헤더,풋터 ----------------------- */
/* Header */
.header-w{background-color:var(--ui-color002);}
  .header-inner{box-sizing:border-box;display:flex;align-items:center;justify-content:center;margin:0 auto;padding:20px 50px;}
  .headerLnb-w{position:relative;background-color:var(--ui-color101);}
  .headerLnb-w.depth_current:before{position:absolute;content:'';opacity:1;left:0;top:76px;width:100%;height:80px;background-color:var(--ui-color107);transition:top .3s;}
    .btn-type001.menu{display:none;}
    .headerLnb-inner{display:flex;align-items:center;justify-content:center;gap:100px;}
      .headerLnb-cont:hover .headerDepth_w,
      .headerLnb-cont.current .headerDepth_w{display:flex;}
        .headerLnb-link{display:block;padding:12px 0;text-decoration:none !important;}
        .headerLnb-link:hover .tit01-white,
        .headerLnb-cont a:hover .tit01-white,
        .headerLnb-cont a.current .tit01-white{color:var(--font-color103);}
      .headerDepth_w{position:absolute;display:none;z-index:5;left:0;top:90px;justify-content:center;align-items:center;flex-direction:row;gap:40px;width:100%;}
      .headerDepth_w .title-type002{text-decoration:none;}
    .headerMobileMenu-w{display:none;position:fixed;z-index:999;left:0;top:60px;width:80%;height:100%;background-color:var(--ui-color002);transition:all .3s;}
    .headerMobileMenu-w .btn-type001.close{position:absolute;left:10px;top:10px;display:block;width:20px;height:20px;}
      .headerMobileMenu-inner{display:flex;flex-direction:column;padding:60px 20px;}
      .headerMobileMenu-inner .title-type002 .tit01{font-size:16px;}

/* Footer */
.footer-w{background-color:var(--ui-color010);}
  .footerCont-top{padding:25px 50px;border-bottom:1px solid var(--ui-color002);}
  .footerCont-bottom{padding:25px 50px;display:flex;flex-direction:row;align-items:center;gap:36px;}
    .footerList-w{}
      .footerList-inner:last-of-type {padding-top:10px;}
      .footerList-inner .title-type003{padding:5px 0;}
      a[href^="tel"]{color:var(--font-color002);}


/* -------------------- 반응형 레이아웃 ----------------------- */
[class*="page-"]{position:relative;}
.container{overflow:hidden;margin:0 auto;padding-bottom:10vh;}
  [class*="content-w"]{padding-top:70px;box-sizing:border-box;display:flex;align-items:center;}

/* 메인 */
.page-main:before{position:absolute;content:'';left:50%;bottom:0;transform:translateX(-50%);width:1700px;height:450px;background-image:url("../image/bg_bubble_pc.png");}
  .content-w-top{background-color:var(--ui-color102);}
  .content-w-top .top-inner{position:relative;margin:0 auto;padding:80px 0 200px;width:1400px;}
  .content-w-top .title-type005{padding-left:150px;flex-direction:column;align-items:flex-start;}
  .content-w-top .title-type005 .logo_top{margin-top:26px;width:540px;height:43px;background-size:100%;background-repeat:no-repeat;background-image:url("../image/logo_title.png");}
  .content-w-top .bg_school {position:absolute;right:0;bottom:-32px;display:block;width:819px;height:393px;background-size:100%;background-image:url("../image/bg_main.png");background-repeat:no-repeat;}
  .content-w-top [class*="bg_line"]{position:absolute;width:120px;height:8px;}
  .content-w-top [class*="bg_line"]:before{position:absolute;content:'';display:block;right:-30px;top:0;width:20px;height:10px;}
  .content-w-top [class*="grey_"], .content-w-top [class*="grey_"]:before{background-color:rgba(255,255,255,.24);}
  .content-w-top [class*="white_"], .content-w-top [class*="white_"]:before{background-color:var(--ui-color002);}
    .grey_right{right:-30px;top:0;}
    .grey_left{left:-100px;bottom:50%;}
    .white_left{left:140px;top:0;}
    .white_right{left:47%;top:0;}
  .content-w-middle{padding-top:95px;flex-direction:column;}
  .content-w-middle .btns-group02{padding-top:10px;}
  .content-w-bottom{justify-content:center;align-items:flex-start;gap:40px;padding:75px 30px 0;width:100%;}
  .content-w-bottom .list-wrap01{width:40%;max-width:580px;}
  .content-w-bottom .list-type01{padding-top:30px;width:100%;}
  .content-w-bottom .title-type002 .tit01{font-weight:700;}

/* 검사소개, 사용방법 */
.content-w-intro,
.content-w-usage{flex-direction:column;justify-content:center;}
  .commonList-w{padding:70px 50px;display:flex;flex-direction:column;max-width:1200px;}
    .commonList-inner{padding-bottom:70px;}
    .commonList-inner .title-type003 .tit01{font-weight:500;}

/* 공통 - 로그인 */
.content-w-login{flex-direction:column;justify-content:center;padding-bottom:20vh;}
  .login-inner{padding-top:70px;}
  .login-inner .radio-form{gap:50px;}
  .login-inner [class*='input-radio'] .txt-label01{color:var(--font-color100);}
  .login-inner .fieldset-type02{margin:0 auto;padding-top:70px;max-width:600px;}
  .login-inner .fieldset-type02 .btns-group03{width:100%;}
  .login-inner .fieldset-type02 .btns-group03 .btn-type005{min-width:calc(50% - 5px);}
  .login-inner .fieldset-type02 .btn-type005.border{color:var(--font-color102);border-color:var(--ui-color105);}
  .login-inner .fieldset-type02 .btn-type005.border:hover{background-color:var(--ui-color002);}

/* 학생 - 등록 */
.content-w-form{display:flex;flex-direction:column;min-height:800px;}
.content-w-form .form-inner{box-sizing:border-box;padding:70px 30px 0;margin:0 auto;max-width:1200px;width:100%;}
.content-w-form .fieldset-type01{justify-content:center;}
.content-w-form .fieldset-type01 .select-wrap{width:calc(35% - 5px);}
.content-w-form .fieldset-type01 .input-group01{width:calc(65% - 5px);}
.content-w-form .title-type003{justify-content:center;width:100%;}
.content-w-form .formDatagrid-w{margin:30px auto;}
/* 탭 영역 */
.content-w-form .formTab-w{margin-bottom:50px;display:flex;align-items:center;justify-content:center;gap:20px;}
  .formTabCont-w{}
    .formTabCont-inner {display:none;}
    .formTabCont-inner.active {display:block;}
  .formBox-notice{box-sizing:border-box;padding:30px 20px;margin:0 auto;width:100%;border-radius:15px;background-color:var(--ui-color109);}
  .formBox-notice .title-type002{flex-direction:column;justify-content:center;text-align:center;gap:20px;word-break:auto-phrase;}
  /* 등록 확인 */
  .formBox-confirm{box-sizing:border-box;padding:30px 20px;margin:0 auto;width:100%;border-radius:15px;border:1px solid var(--ui-color105);background-color:var(--ui-color110);}
  .formBox-confirm .title-type002{flex-direction:column;align-items:flex-start;text-align:left;gap:20px;}
  .formBox-confirm .title-type002 .tit01-blue{font-weight:700;}
  /* 검사지 타입 : 디폴트 블랙보더 */
  [class*="formBox-test"]{box-sizing:border-box;width:100%;padding:30px 50px;border-radius:60px;border:4px solid var(--ui-color001);}
  /* 검사 인트로*/
  .formBox-testBlue{margin-top:30px;display:inline-flex;align-items:center;flex-direction:column;padding:50px 60px;border-color:var(--ui-color111);background-color:var(--ui-color012);}
  .formBox-testBlue .btn-type005.yellow{margin-top:30px;}
  /* 검사지 기본 */
  .formBox-test{padding:0;}
    .formBoxTest-top{padding:30px 50px;background-color:var(--ui-color113);border-radius:60px 60px 0 0;border-bottom:4px solid var(--ui-color001);}
    .formBoxTest-middle{padding:30px 50px;}
    .formBoxTest-middle .formBoxTest-img{display:block;margin:0 auto;width:100%;max-width:600px;}
      .testList_w{padding-top:10px;}
        .testList_inner{padding:40px 0;display:flex;align-items:flex-start;flex-direction:row;gap:30px;border-bottom:1px solid var(--ui-color006);}
        .testList_inner:last-of-type{border-bottom:0;padding-bottom:0;}
        .testList_inner .title-type002{width:60%;padding-top:0;}
        .testList_inner .radio-form{flex-direction:column;width:40%;}
/* 학생 - 내정보 */
.page-student-mypage{}
.page-student-mypage .formBox-confirm{margin-top:70px;max-width:600px;}
.page-student-mypage .formBox-confirm .title-type002{flex-direction:row;}
/* 학교장애인식검사 결과지 */
.page-result{background-color:var(--ui-color013);}
  .content-w-result{padding-top:0;flex-direction:column;justify-content:center;}
  .content-w-result [class*="resultInner"]{display:flex;flex-direction:column;}
    .resultInner-top{padding:50px 0;align-items:center;width:100%;}
    .resultInner-top .btn-type004.print{margin-bottom:80px;background-color:var(--ui-color002);}
    .resultInner-top .btn-type004.print:hover{background-color:var(--ui-color010);}
    .resultInner-top .btn-type004 .ico-type002.print{margin-right:15px;}
    .resultInner-top .btn-type004:hover .ico-type002.print{filter:var(--filter-color001);}
    .resultInner-top .logo-result{margin-bottom:30px;}
    .resultInner-top .result-user{padding-bottom:50px;display:block;font-size:46px;font-weight:500;color:var(--font-color100);}
    .resultInner-top .result-title{padding-bottom:60px;font-size:28px;font-weight:700;text-align:center;}
    [class*="resultInner"] [class*="result-text"]{display:block;font-size:24px;width:calc(100% - 60px);max-width:1500px;}
    [class*="resultInner"] .result-text-white{padding-bottom:16px;color:var(--font-color002);}
    .resultInner-top .box-yellow{margin:40px 0 80px;width:100%;padding:30px 20px;text-align:center;background-color:var(--ui-color113);border-top:4px solid var(--ui-color001);border-bottom:4px solid var(--ui-color001);}
    .resultInner-top .box-yellow .result-text{padding-top:0;margin:0 auto;display:block;}
    .resultInner-top .result-chart{position:relative;z-index:2;display:block;box-sizing:border-box;padding:50px 20px 20px;width:calc(100% - 80px);max-width:1500px;background-color:var(--ui-color002);border:4px solid var(--ui-color001);border-radius:60px;box-shadow:10px 10px #333}
    .resultInner-top .result-chart .result-title{padding-bottom:0;}
      .resultInner-top .result-chart:after{position:absolute;z-index:3;content:'';right:5%;top:-60px;display:block;width:124px;height:129px;background-size:100%;background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='128.408' height='132.57' viewBox='0 0 128.408 132.57'%3E%3Cpath id='Icon_feather-paperclip' data-name='Icon feather-paperclip' d='M102.4,50.9,53.99,99.316A31.626,31.626,0,0,1,9.263,54.59L57.677,6.175A21.084,21.084,0,0,1,87.5,35.993L39.028,84.407A10.542,10.542,0,0,1,24.119,69.5L68.846,24.825' transform='translate(126.609 107.596) rotate(167)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;}
    .resultInner-top canvas{margin:30px auto;display:block;width:100%;max-width:800px;}
    .resultInner-top .chart-inner{display:flex;flex-wrap:wrap;justify-content:center;gap:30px;padding:50px 30px;background-color:var(--ui-color113);border-radius:0 0 40px 40px;}
    .resultInner-top [class*="chartLabel-w"]{display:flex;flex-direction:column;gap:20px;}
    .resultInner-top [class*="chartLabel-w"] .result-title{padding-bottom:0;}
    .resultInner-middle{width:100%;padding:0 30px 30px;background-color:var(--ui-color002);border-top:4px solid var(--ui-color001);border-bottom:4px solid var(--ui-color001);}
    .resultInner-middle .result-table{margin:0 auto;width:100%;max-width:1400px;}
    .resultInner-middle .resultTable-head{padding:30px;display:flex;justify-content:center;align-items:center;}
    .resultInner-middle .rth-inner{display:block;width:25%;text-align:center;font-size:28px;color:var(--font-color001);font-weight:700;}
    .resultInner-middle .rth-inner:last-of-type{width:50%;}
    .resultInner-middle .resultTable-body{}
    .resultInner-middle [class*="rtb-w"]{padding:40px 0;display:flex;flex-direction:row;}
      .rtb-w-pink{background-color:var(--font-color105);}
      .rtb-w-blue{background-color:var(--font-color106);}
      .rtb-w-yellow{background-color:var(--font-color103);}
      .rtb-w-mint{background-color:var(--font-color107);}
    .resultInner-middle .rtb-inner{display:flex;align-items:center;justify-content:center;width:50%;}
    .resultInner-middle .rtb-inner .resultText-w{box-sizing:border-box;padding:20px;width:55%;text-align:center;}
    .resultInner-middle .rtb-inner [class*="result-text"]{font-weight:700;}
    /*.resultInner-middle .rtb-inner .ico-label-white{width:50%;}*/
    .resultInner-bottom{width:100%;padding:30px;background-color:var(--ui-color002);}
    .resultInner-bottom .result-image{margin:0 auto;display:block;width:100%;max-width:1400px;}
    .resultInner-list{box-sizing:border-box;padding:50px 30px;width:100%;max-width:1400px;}
    .resultInner-list ul>li{padding-bottom:15px;}
    .resultInner-list ul>li>a{font-size:18px;color:var(--font-color005);font-weight:700;}

/* 교원 */
.page-teacher{}
  /* 교원 - 내정보 (추후에 학교관리자,교육청 등 공통으로 사용가능) */
  .content-w-my{flex-direction:column;justify-content:center;padding-bottom:20vh;}
    .my-inner{padding-top:70px;}
    .my-inner .fieldset-type02{margin:0 auto;width:100%;max-width:600px;}

/* 관리자 */
[class*="page-admin"]{padding-top:78px;}
[class*="page-admin"] .content-w-form{position:relative;}
[class*="page-admin"] .admin-indicator{position:absolute;top:20px;left:50%;transform:translateX(-50%);margin-left:430px;}
[class*="page-admin"] .admin-indicator .txt-type001{white-space:nowrap;}
  /* 관리자 - 교육청 */
  .page-admin .content-w-form .fieldset-type02{padding-bottom:30px;margin:0 auto;max-width:700px;}
  .page-admin .content-w-form .btns-group03{width:100%;}
  .page-admin .content-w-form .formBox-notice{margin:30px auto;max-width:1140px;}
  .page-admin .content-w-form .formBox-notice .input-group02{margin:0 auto;max-width:900px;}
  .page-admin .content-w-form .formBox-notice .fieldset-type01{padding-bottom:0;}
  .page-admin .content-w-form .formTab-w{margin:30px 0 0;}
  .page-admin .input-group02 .title-type002{min-width:160px;}
  .page-admin .fieldset-type03{display:flex;flex-direction:column;gap:20px;margin:0 auto;} /* input='file' 타입 */
  .page-admin .fieldset-type03 .input-group02 .title-type002{min-width:100px;}

/* 공통 모달창 */
[class*="modal-w"]{display:none;position:fixed;z-index:990;left:50%;top:50%;width:100%;height:100%;transform:translate(-50%,-50%);}
[class*="modal-w"].active{display:block;}
[class*="modal-w"] .dimm{position:absolute;left:0;top:0;width:100%;height:100%;background-color:rgba(0,0,0,.7);}
  [class*="modal-inner"]{overflow-y:auto;overflow-x:hidden;box-sizing:border-box;display:flex;align-items:center;flex-direction:column;position:absolute;z-index:999;left:50%;top:50%;transform:translate(-50%,-50%);max-width:1400px;max-height:95%;padding:60px;background-color:var(--ui-color002);border-radius:10px;}
  [class*="modal-inner"]::-webkit-scrollbar{width:2px;background-color:var(--ui-color000);}
  [class*="modal-inner"]::-webkit-scrollbar-thumb{width:2px;background-color:var(--ui-color000);}
  [class*="modal-inner"]::-webkit-scrollbar-track{width:2px;background-color:var(--ui-color000);}
  [class*="modal-inner"] .title-type001{padding-bottom:30px;width:100%;justify-content:center;}
  [class*="modal-inner"] .btn-type005.close{margin-top:30px;}
  .modal-inner-type80{width:80%;}
    /* 모달 - 검사지 */
    [class*="testModal-"]{position:relative;}
    [class*="testModal-"] .btn-type001.close{position:absolute;right:10px;top:10px;display:block;}
    [class*="testModal-"] .title-type001{width:auto;padding:30px 0;}
    [class*="testModal-"] .btns-group02{margin-top:30px;}
    [class*="testModal-"] .btns-group02 .btn-type005.border:hover{border-color:var(--ui-color101);background-color:var(--ui-color002);color:var(--font-color100);text-decoration:none;}
  .modal-inner-type60{width:60%;}
  .modal-inner-type45{width:45%;max-width:600px;}
  .modal-inner-type45 .fieldset-type02+.fieldset-type02{padding-top:30px;}
  .modal-inner-type35{width:35%;max-width:565px;}
  .modal-inner-type35 .title-type001,
  .modal-inner-type25 .title-type001{justify-content:center;}
  .modal-inner-type25{width:25%;max-width:400px;}
/* 관리자 모달 (블루컬러 보더, R값 없음) */
.modal-w-admin [class*="modal-inner"]{box-sizing:border-box;width:auto;border-radius:0;border:15px solid var(--ui-color114);}
.modal-w-admin .title-type001 .tit01.blue {color:var(--font-color100);}
.modal-w-admin .btn-type001.close{position:absolute;top:10px;right:10px;}
.modal-w-admin .input-column{display:flex;flex-direction:column;gap:20px;width:100%;}
.modal-w-admin .input-group02:has(.input-column){align-items:flex-start;}

/* media query */
@media all and (max-width:1400px) {.content-w-top .bg_school{width:680px;height:332px;}}
@media all and (max-width:1250px) {.content-w-top .bg_school{bottom:-23px;width:520px;height:252px;}}
@media all and (max-width:1024px){
  /* 헤더, 풋터 */
  .headerLnb-inner{flex-wrap:wrap;gap:0 30px;}
  .headerLnb-inner .title-type002 [class*="tit01"]{font-size:20px;}
  .headerLnb-w.depth_current:before{top:67px;height:64px;}
  .headerDepth_w{top:77px;}
  .header-inner,
  .footerCont-top{padding:1rem 1.5rem;}
  .footerCont-bottom{padding:1.5rem;}
  /* 공통 모달창 */
  .modal-inner{padding:30px;}
  .modal-inner-type45{width:65%;}
  .modal-inner-type35{width:55%;max-width:400px;}
  .modal-inner-type25{width:40%;}
    /* 검사지 기본 */
    [class*="testModal-"] .btn-type001.close{right:0;top:0;}
    [class*="testModal-"] .btns-group02{gap:10px;}
    [class*="testModal-"] .btns-group02 .btn-type005.border{padding:16px 20px;}
    .formBox-test{border-radius:40px;}
      .formBoxTest-top{padding:30px;border-radius:40px 40px 0 0;}
      .formBoxTest-middle{padding:30px;}
        .testList_inner{padding:30px 0;flex-direction:column;gap:20px;}
        .testList_inner .title-type002,
        .testList_inner .radio-form{width:100%;}
  /* 레이아웃 */
  .content-w-top .top-inner{padding:50px 0 120px;}
  .content-w-top .title-type005{padding-left:70px;}
  .content-w-top .title-type005 .tit01{font-size:15px;}
  .content-w-top .title-type005 .logo_top{max-width:400px;height:32px;}
  .content-w-top .bg_school{bottom:-35px;width:460px;height:238px;}
  .content-w-top [class*="bg_line"]{width:70px;height:5px;}
  .content-w-top [class*="bg_line"]:before{width:20px;height:5px;}
    .grey_left{left:0;bottom:50px;}
    .grey_right{right:40px;}
    .white_left{left:90px;}
    .white_right{display:none;}
  .content-w-top img{bottom:-24px;width:45vw;height:20vw;}
  .content-w-bottom{gap:30px;}
  .commonList-w .title-type002 [class*="tit01"]{font-size:18px;}
  /* 공통 - 로그인 */
  .content-w-login{}
  .login-inner .radio-form{gap:30px;}
  .login-inner .fieldset-type02{max-width:500px;}
  /* 등록 */
  .formBox-testBlue{padding:30px 40px;}
  .formBox-confirm .title-type002{gap:10px;} /* 학생등록인증 */
  .formBox-confirm .title-type002 [class*="tit01"]{font-size:20px;}
  /* 학생 - 내정보 */
  .page-student-mypage .formBox-confirm{width:90%;max-width:340px;}
  /* 학교장애인식검사 결과지 */
  [class*="resultInner"] [class*="result-text"]{font-weight:500;font-size:18px;}
  .resultInner-top .btn-type004.print{margin-bottom:50px;padding:18px 40px;}
  .resultInner-top .result-user{font-size:30px;}
  .resultInner-top canvas{max-width:600px;}
  .resultInner-top .chart-inner{gap:20px;}
  .resultInner-top [class*="chartLabel-w"]{gap:10px;}
  .resultInner-top [class*="chartLabel-w"] .result-title{font-size:24px;}
  .resultInner-middle .resultTable-head{padding:20px 0;}
  .resultInner-middle .rth-inner{font-size:24px;}
  .resultInner-middle .rtb-inner .resultText-w{padding:10px;}
  .resultInner-middle .rtb-inner [class*="result-text"]{font-size:18px;}
  .resultInner-list ul>li{padding-bottom:12px;}
  .resultInner-list ul>li>a{font-size:16px;}
}
@media all and (max-width:768px){
  /* 헤더, 풋터 */
  .header-inner{padding:8px 15px;}
  .headerLnb-w{padding:5px 10px;}
  .headerLnb-w.depth_current:before{display:none;}
  .headerLnb-inner{display:none;}
  .btn-type001.menu{display:block;}
  .btn-type001.menu .ico-type001.menu{width:15px;height:10px;}
  .headerMobileMenu-w.current{display:block;}
  .footerCont-top{padding:12px 15px;}
  .footerCont-bottom{flex-direction:column;align-items:flex-start;gap:20px;padding:12px 15px;}
    .footerList-inner{display:flex;align-items:flex-start;}
    .footerList-inner>strong{width:150px;margin-right:10px;}
  /* 공통 모달창 */
  [class*="modal-inner"]{padding:30px 20px;}
  .modal-inner-type80{width:85%;}
  .modal-inner-type60{width:80%;}
  .modal-inner-type45,
  .modal-inner-type35{width:85%;}
  .modal-inner-type25{width:70%;}
  /* 검사지 기본 */
    [class*="formBox-test"]{border-radius:30px;}
    .formBoxTest-top{padding:15px 20px;border-radius:30px 30px 0 0;}
    .formBoxTest-middle{padding:15px 20px 40px;}
  /* 레이아웃 */
  .page-main:before{width:375px;height:500px;background-size:100%;background-image:url("../image/bg_bubble_m.png");}
  .content-w-top .top-inner{padding:0 0 140px;}
  .content-w-top .title-type005{padding:0;align-items:center;}
  .content-w-top .title-type005 .logo_top{margin-top:10px;width:195px;height:64px;background-image:url("../image/logo_title_m.png");}
  .content-w-top .bg_school{z-index:3;right:50%;transform:translateX(50%);width:300px;height:168px;}
    .grey_left{bottom:240px;}
    .grey_right{right:-10px;top:-30px;}
    .white_left{left:20px;top:140px;}
    .white_right{display:block;top:130px;left:340px;}
  .content-w-top img{bottom:-18px;width:100%;max-width:375px;height:46%;}
  .content-w-middle{padding-top:55px;}
  .content-w-bottom{flex-direction:column;gap:20px;padding:40px 15px 0;}
  .content-w-bottom .list-wrap01{padding:1rem;width:calc(100% - 30px);max-width:100%;}
  .content-w-bottom .list-type01{padding-top:15px;}
  .commonList-w{padding:50px 15px;}
  .commonList-w .title-type002 [class*="tit01"]{font-size:15px;}
  /* 공통 - 로그인 */
  .content-w-login{}
  .login-inner{padding:50px 20px 0;}
  .content-w-login .radio-form{gap:10px;flex-wrap:wrap;justify-content:center;}
  .content-w-login .radio-form .input-radio01{width:calc(50% - 10px);}
  .login-inner .fieldset-type02{padding-top:50px;max-width:100%;}
  .login-inner .fieldset-type02 .input-group02{gap:10px;}
  .login-inner .fieldset-type02 .input-group02 .title-type002{width:70px;}
  /* 등록 */
  .content-w-form{min-height:600px;}
  .content-w-form .form-inner{padding:50px 15px 0;max-width:100%;}
  .content-w-form .fieldset-type01 .select-wrap,
  .content-w-form .fieldset-type01 .input-group01{width:100%;}
    .formBox-notice{padding:20px 10px;}
    .formBox-testBlue{padding:30px 20px;border-radius:30px;}
  /* 학생 - 내정보 */
  .page-student-mypage .formBox-confirm .title-type002{gap:10px;}
  .page-student-mypage .formBox-confirm .title-type002 [class*="tit01"]{font-size:16px;}
  /* 학교장애인식검사 결과지 */
  [class*="resultInner"] [class*="result-text"]{font-size:14px;}
  .resultInner-top .btn-type004.print{padding:10px 20px;}
  .resultInner-top .result-user{font-size:18px;padding-bottom:30px;}
  .resultInner-top canvas{margin:20px auto;}
  .resultInner-top .box-yellow{margin:30px 0 40px;padding:20px 10px;}
  .resultInner-top .result-chart{padding:40px 10px 10px;width:calc(100% - 40px);box-shadow:4px 4px #333;border-radius:30px;}
  .resultInner-top .result-chart:after{top:-30px;width:56px;height:58px;}
  .resultInner-top .result-chart .result-title{font-size:20px;}
  .resultInner-top .chart-inner{padding:30px 15px;flex-direction:column;gap:15px;border-radius:0 0 20px 20px;}
  .resultInner-top [class*="chartLabel-w"]{flex-direction:row;align-items:center;justify-content:space-between;}
  .resultInner-top .chartLabel-w-total{flex-direction:column;}
  .resultInner-middle{padding:0 20px 0;}
  .resultInner-middle .resultTable-head{padding:15px 10px;}
  .resultInner-middle .rth-inner{font-size:18px;}
  .resultInner-middle [class*="rtb-w"]{flex-direction:column;gap:10px;padding:20px 0;}
  .resultInner-middle .rtb-inner{width:100%;}
  .resultInner-middle .rtb-inner [class*="result-text"]{font-size:16px;line-height:1.5;}
  .resultInner-list{padding:30px 20px;}
  .resultInner-list ul>li{padding-bottom:8px;}
  .resultInner-list ul>li>a{font-size:12px;}
  /* 관리자 (반응형x) */
  [class*="page-admin"]{padding-top:0;}
}